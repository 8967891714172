import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Slider from "./slider/Slider";
import PartyBoxes from "./PartyBoxes";
import HighlightBoxes from "./HighlightBoxes";
import { Link } from "react-router-dom";
import NightlifeExperience from "./Pages/NightlifeExperience";
import HiringPartners from "./Pages/HiringPartners";
import ScrollAdd from "./ScrollAdd";
import EventCancellationNotice from "./Pages/EventCancellationNotice";

const Home = () => {
  const images = [
    "./images/slideimg-1.jpg",
    "./images/slideimg-2.jpg",
    "./images/slideimg-3.jpg",
    "./images/slideimg-4.jpg",
    "./images/slideimg-5.jpg",
    "./images/slideimg-6.jpg",
    "./images/slideimg-7.jpg",
    "./images/slideimg-8.jpg",
    "./images/slideimg-9.jpg",
  ];

  const [index, setIndex] = useState(0);
  const image = images[index];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  const services = [
    {
      icon: "./images/schedule-icon.png",
      title: "Schedule and Time",
      path: "/schedule",
    },
    { icon: "./images/dj-icon.png", title: "DJ and Artists", path: "/dj" },
    {
      icon: "./images/stay-icon.png",
      title: "Camping and Stay",
      path: "/stay",
    },
    { icon: "./images/food-icon.png", title: "Drinks and Food", path: "/food" },
  ];

  return (
    <>
      <div className="up-two"></div>

      <div className="slideshow-main-div">
        <div key={index}>
          <img
            className="slideshow-image"
            src={image}
            alt={`Image ${index + 1}`}
          />
        </div>
      </div>

      <EventCancellationNotice />

      <div className="home-main-box">
        <h1 className="neonText head1">WELCOME TO EVENTFLOW.ASIA</h1>
        <h2 className="neonText head1">
          Crafting Unforgettable Experiences Since 2010
        </h2>
        <p>
          At Eventflow.asia, we transform your vision into memorable
          celebrations. With over a decade of experience, we’ve mastered the art
          of organizing high-end corporate events, weddings, sports events, and
          nightlife parties. Our new online presence showcases our journey and
          opens the door to new opportunities.
        </p>
      </div>

      <ScrollAdd />

      {/* <Slider /> */}

      <div className="boxx-02" style={{ fontFamily: "Cinzel Decorative" }}>
        <h3 className="neonText heading2 head1">Upcoming Event</h3>
        <hr className="divider2" />
        <h1 className="neonText heading2 head1">
          Corporate Night Life Cocktail Party
        </h1>
        <p>
          Join us for an unforgettable corporate cocktail party. Immerse
          yourself in a night filled with vibrant music, endless cocktails, and
          gourmet kebabs — a perfect blend of professional networking and fun!
        </p>
      </div>

      {/* <PartyBoxes /> */}
      {/* <NightlifeExperience /> */}

      <div className="hm-highlights">
        <div className="hm-box">
          <p>Unlimited Cocktails – Sip on premium drinks all night long.</p>
          <hr />
          <p>
            Unlimited Kebabs – Indulge in a variety of mouth-watering kebabs.
          </p>
          <hr />
          <p>
            Unlimited Dinner – Savor an exquisite dinner with all your
            favorites.
          </p>
          <hr />
          <p>
            Unlimited Breakfast – Enjoy a delicious breakfast the next morning.
          </p>
          <hr />
          <p>
            Free Camping – Stay under the stars in luxurious camp
            accommodations.
          </p>
          <hr />
          <p>
            Live Games – Join in on exciting, interactive games with friends and
            colleagues.
          </p>
          <hr />
          <p>Disco Dance – Hit the dance floor with energetic beats.</p>
          <hr />
        </div>

        <div className="hm-box">
          <p>
            Salsa Dance – Sway to the rhythms of Salsa and heat up the dance
            floor.
          </p>
          <hr />
          <p>Ecstatic Dance – Move freely and embrace the rhythm.</p>
          <hr />
          <p>Fire Show – Get mesmerized by an electrifying fire performance.</p>
          <hr />
          <p>
            Free Flow Art Show – Witness stunning live art performances that
            flow with the night.
          </p>
          <hr />
          <p>
            Live DJ Fusion – Groove to Bollywood beats, Techno, and an eclectic
            fusion of music.
          </p>
          <hr />
          <p>
            Human Library – Connect, share, and learn from unique personal
            stories.
          </p>
          <hr />
          <p>
            Fashion Expo – Discover the latest trends at our fashion showcase.
          </p>
          <hr />
        </div>
      </div>

      <h1 className="neonText title head1">EVENT HIGHLIGHTS</h1>

      <HighlightBoxes />

      <div className="button-container">
        {/* <Link to="https://lifehisto.com/" className="styled-link">
          <button className="book-button">BOOK NOW</button>
        </Link> */}
        {/* <Link to="https://lifehisto.com/" className="styled-link">
        <button className="book-button">
          <img
            src="./images/booknow.png" // Replace with your icon URL
            alt="Book Icon"
            className="button-icon"
          />
          BOOK NOW
        </button>
      </Link> */}
      </div>

      <div className="venue-container">
        <img
          src="./images/loc-icon.png"
          alt="Venue Icon"
          className="venue-icon"
        />
        <h2 className="venue-title neonText head1">
          Venue: Harivillu Resorts <br />
          <span className="venue-location neonText">( Vikarabad )</span>
        </h2>
      </div>

      <div className="services-container">
        {services.map((service, index) => (
          <Link to={service.path} key={index} className="service-box">
            <img
              src={service.icon}
              alt={service.title}
              className="service-icon"
            />
            <h3 className="service-title head1">{service.title}</h3>
          </Link>
        ))}
      </div>

      <div className="boxx-02">
        <h2 className="neonText head1">Why Choose Eventflow.asia?</h2>
        <p>
          Proven Track Record: Organizing stunning events since 2010, from
          corporate conferences to exclusive weddings and sports events.
        </p>

        <hr className="divider2" />

        <p>
          Tailored Services: We design personalized experiences, ensuring that
          every event is as unique as your vision.
        </p>
        <hr className="divider2" />
        <p>
          Experienced Team: A team of skilled professionals who understand the
          nuances of event planning.
        </p>
        <hr className="divider2" />
        <p>
          Attention to Detail: From décor to entertainment, every aspect of the
          event is curated for perfection.
        </p>
      </div>

      <div className="boxx-02">
        <h2 className="neonText head1">Our Expertise</h2>
        <p>
          Corporate Events: From team-building retreats to luxurious cocktail
          parties, we tailor every detail to reflect your brand’s image and
          values.
        </p>
        <hr className="divider2" />
        <p>
          Weddings: Whether it’s a grand traditional wedding or an intimate
          destination celebration, we design events that leave lasting memories.
        </p>
        <hr className="divider2" />
        <p>
          Sports Events: Our expertise in organizing sports events ensures that
          everything runs smoothly, from logistics to entertainment.
        </p>
        <hr className="divider2" />
        <p>
          Nightlife Parties: Elevate your corporate and social gatherings with
          our vibrant cocktail parties and themed night events that promise fun
          and sophistication.
        </p>
      </div>

      <HiringPartners />

      <div className="home-main-box2">
        <div className="gas ar">
          <span className="number head1">Hosted by</span>
          <div className="symbol head1">EventFlow.Asia</div>
          {/* <p>Powered by LifeHisto.com Pvt Ltd</p> */}
        </div>
      </div>
    </>
  );
};

export default Home;

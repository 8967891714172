import React from "react";
import Marquee from "react-fast-marquee";

const ScrollAdd = () => {
  return (
    <Marquee
      style={{
        backgroundColor: "#000",
        color: "red",
        padding: "6px",
        fontFamily: '"Inter", sans-serif',
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
      }}
      gradient={false}
      speed={40}>
      <p>
        <span className="scroll-text-gradent" style={{ marginRight: "50px" }}>
          "Due to permission issues, we are unable to continue with the live PUBG session."
        </span>{" "}
        <span className="scroll-text-gradent" style={{ marginRight: "50px" }}>
          "Due to permission issues, we are unable to continue with the live PUBG session."
        </span>{" "}
        <span className="scroll-text-gradent" style={{ marginRight: "50px" }}>
          "Due to permission issues, we are unable to continue with the live PUBG session."
        </span>{" "}
        <span className="scroll-text-gradent" style={{ marginRight: "50px" }}>
          "Due to permission issues, we are unable to continue with the live PUBG session."
        </span>{" "}
        <span style={{ marginRight: "50px" }}>
          "Due to permission issues, we are unable to continue with the live PUBG session."
        </span>{" "}
        <span className="scroll-text-gradent" style={{ marginRight: "50px" }}>
          "Due to permission issues, we are unable to continue with the live PUBG session."
        </span>
        <span className="scroll-text-gradent" style={{ marginRight: "50px" }}>
          "Due to permission issues, we are unable to continue with the live PUBG session."
        </span>
        <span className="scroll-text-gradent" style={{ marginRight: "50px" }}>
          "Due to permission issues, we are unable to continue with the live PUBG session."
        </span>
      </p>
    </Marquee>
  );
};

export default ScrollAdd;

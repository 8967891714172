// Food.js
import React from 'react';

const Food = () => {
  return (
    <>
      <div className='up-two'></div>
      <div className="food-container">
        <h2 className="title neonText head2">Food & Drinks</h2>
        <p className="food-description">
          Enjoy a curated menu that balances indulgent flavors with refreshing drinks and filling meals!
        </p>
        
        <div className="food-menu">
          {/* Cocktail Menu */}
          <div className="menu-category">
            <h3 className='head2'>Cocktail Menu</h3>
            <p>Here’s a lineup of refreshing and innovative cocktails that combine unique flavors and classic mixes to energize your night:</p>
            <ul>
              <li><strong className='head2'>Virgin Sports</strong> - A revitalizing cocktail made with a mix of vodka, white rum, soda, fresh orange slices, and sugar syrup. Perfect for a light and refreshing start to the night.</li>
              <li><strong className='head2'>CocoGin</strong> - A tropical delight featuring gin mixed with coconut water and honey. This sweet, smooth drink pairs perfectly with grilled appetizers and kebabs.</li>
              <li><strong className='head2'>Fire Shots</strong> - Flaming shots with a kick! These exciting, fiery drinks will add a spark to your party with their hot and bold flavor profile.</li>
              <li><strong className='head2'>Whisky Sour</strong> - A timeless whisky cocktail made with whisky, fresh lemon juice, sugar, and a dash of bitters, offering a balanced taste of sweet and sour.</li>
              <li><strong className='head2'>Whisky Highball</strong> - A light, fizzy drink made by combining whisky with soda water, served over ice for a refreshing experience.</li>
              <li><strong className='head2'>Brandy with Warm Water</strong> - A simple yet classic drink, warming brandy mixed with hot water to create a soothing, relaxing beverage perfect for cooler nights.</li>
            </ul>
          </div>

          {/* Kebabs Menu */}
          <div className="menu-category">
            <h3 className='head2'>Kebabs Menu</h3>
            <p>Delicious, smoky, and flavorful kebabs grilled to perfection, adding depth to the culinary experience:</p>
            <ul>
              <li><strong className='head2'>Tandoori Chicken Kebabs</strong> - Boneless chicken marinated in yogurt, spices, and herbs, skewered and grilled in a tandoor, delivering a smoky flavor with a juicy texture.</li>
              <li><strong className='head2'>Pineapple Kebabs</strong> - Sweet and savory, pineapple chunks are marinated in a tangy sauce and lightly grilled for a caramelized finish, balancing sweetness with a hint of spice.</li>
              <li><strong className='head2'>Aloo Kebabs</strong> - Spiced mashed potatoes formed into patties, coated in breadcrumbs, and grilled until golden, offering a crispy outside and soft inside.</li>
              {/* <li><strong className='head2'>Tandoori Prawns</strong> - Fresh prawns marinated in a spiced yogurt mixture, then skewered and cooked over an open flame, resulting in succulent and flavorful bites.</li> */}
              <li><strong className='head2'>Fish Fry</strong> - Marinated chunks of fish grilled with aromatic spices, producing tender, flaky fish with a slight char and rich taste.</li>
            </ul>
          </div>

          {/* Dinner Menu */}
          <div className="menu-category">
            <h3 className='head2'>Dinner Menu</h3>
            <p>A fulfilling meal that offers a variety of traditional dishes to satisfy every palate:</p>
            <ul>
              <li><strong className='head2'>Bagara Rice</strong> - Fragrant basmati rice cooked with whole spices, curry leaves, and coconut, offering a subtle yet rich flavor.</li>
              <li><strong className='head2'>Chicken Curry</strong> - A hearty chicken dish simmered in a gravy made from onions, tomatoes, and traditional Indian spices, perfect for pairing with rice or bread.</li>
              <li><strong className='head2'>Paneer Curry</strong> - Soft paneer cubes cooked in a thick, spiced tomato and onion gravy, offering a rich vegetarian option with a creamy texture.</li>
              <li><strong className='head2'>White Rice</strong> - Simple and lightly seasoned steamed white rice, serving as a staple to accompany the rich curries.</li>
              <li><strong className='head2'>Sambar</strong> - A tangy and flavorful lentil-based vegetable stew with a hint of tamarind and a blend of South Indian spices.</li>
              <li><strong className='head2'>Curd</strong> - Cooling and smooth yogurt, traditionally served to complement spicy dishes and provide a refreshing balance.</li>
              <li><strong className='head2'>Pickle</strong> - A variety of tangy Indian pickles made from mango or lime, adding a burst of flavor to the meal.</li>
              {/* <li><strong className='head2'>Ice Cream</strong> - A sweet, creamy dessert to end the meal on a refreshing note, available in different flavors.</li> */}
            </ul>
          </div>

          {/* Morning Breakfast Menu */}
          <div className="menu-category">
            <h3 className='head2'>Morning Breakfast Menu</h3>
            <p>To recharge after the party, a satisfying breakfast will be served the next morning:</p>
            <ul>
              <li><strong className='head2'>Poori with Aloo Curry</strong> - Fluffy, deep-fried Indian bread served with a lightly spiced potato curry for a hearty breakfast.</li>
              
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Food;

import React from 'react';

const Schedule = () => {
  return (
    <>
      <div className='up-two'></div>
      <div className="schedule-container">
      <h1 className='head1'>Event Schedule</h1>
      <div className="schedule-section">
        <h2 className='head2'>Baha Game</h2>
        <ul>
          <li>
            <strong className='head2'>Practice Session:</strong> 3:00 PM - 5:30 PM (Free)
          </li>
          <li>
            <strong className='head2'>Tournament:</strong> 6:00 PM - 7:00 PM
          </li>
          <li>Winners will receive cash prizes.</li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Live Games</h2>
        <ul>
          <li>
            <strong className='head2'>Bull Ride:</strong> ₹150 per ride. Longest ride wins ₹5,000 - ₹10,000.
          </li>
          <li>
            <strong className='head2'>Visual Reflection Game:</strong> Entry Fee ₹100.
          </li>
          <li>
            <strong className='head2'>Test Your Strength:</strong> Entry Fee ₹100.
          </li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Chess Tournament</h2>
        <ul>
          <li>
            <strong className='head2'>Time:</strong> 7:00 PM - 8:00 PM
          </li>
          <li>
            <strong className='head2'>Entry Fee:</strong> ₹100
          </li>
          <li>
            <strong className='head2'>Prize:</strong> ₹1,000 - ₹10,000 (Depends on entries)
          </li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Beer Pong</h2>
        <ul>
          <li>
            <strong className='head2'>Free Entry</strong>
          </li>
          <li>
            <strong className='head2'>Time:</strong> 3:00 PM - 4:00 PM
          </li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Who Drinks Beer First</h2>
        <ul>
          <li>
            <strong className='head2'>Free Entry</strong>
          </li>
          <li>
            <strong className='head2'>Time:</strong> 4:00 PM - 4:30 PM (2-3 rounds)
          </li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Human Library</h2>
        <ul>
          <li>
            <strong className='head2'>Free Entry:</strong> 
            <strong className='head2'>Setup:</strong>
            <li>
            Tables and chairs arranged like a club.
            </li>
            <li>
            Each table features a discussion topic.
            </li>
            <li>
            Participants can choose tables, ask questions, and engage in discussions.
            </li>

          </li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Cornhole Tournament</h2>
        <ul>
          <li>
            <strong className='head2'>Training:</strong>  5:00 PM - 6:00 PM
          </li>
          <li>
            <strong className='head2'>Tournament:</strong> 12:00 AM
          </li>
          <li>
            <strong className='head2'>Description:</strong> A popular U.S. game now in India.
          </li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Dapo (Free Flow Art)</h2>
        <ul>
            <strong className='head2'>Activities:</strong>
          <li>
          Training sessions.
          </li>
          <li>
          Tournament.
          </li>
          <li>
          Dapo sale stall available throughout the event.
          </li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Cricket Tournament</h2>
        <ul>
            <strong className='head2'>Box Cricket Format:</strong>
          <li>
          Teams of 5 players each.
          </li>
          <strong className='head2'>Tournament Time:</strong>

          <li>
          Under floodlights.
          </li>
          <strong className='head2'>Prize Money:</strong>₹5,000 - ₹10,000 (Depends on entries).
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Shuttle Tournament</h2>
        <ul>
            
          <li>
          <strong className='head2'>Time:</strong>6:00 PM - 8:00 PM
          </li>
          <li>
          <strong className='head2'>Prize Money:</strong>₹2,000 - ₹10,000 (Based on registrations).
          </li>
        </ul>
      </div>


      <div className="schedule-section">
        <h2 className='head2'>DJ Night</h2>
        <ul>
          <li>
            <strong className='head2'>8:00 PM - 12:30 AM:</strong> Performances by DJs Nagma, Asen Official, Karma, Wajeehuddin, and a surprise act.
          </li>
          <li>8:00 PM - 9:00 PM: DJ Nagma (NAD)</li>
          <li>9:00 PM - 10:00 PM: DJ Asen Official</li>
          <li>10:00 PM - 11:00 PM: DJ Karma</li>
          <li>11:00 PM - 12:00 AM: DJ Wajeehuddin</li>
          <li>12:00 AM - 12:30 AM: Surprise Act</li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Event Highlights</h2>
        <ul>
          <li>
          Russian Fire Show & Free Flow (LED) Arts (8:00 PM - 12:00 AM).
          </li>
          <li>Cocktail Shots: Variety available at the bar counter.</li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Morning Activities</h2>
        <ul>
          <li>
            <strong className='head2'>Trekking:</strong> 6:00 AM - 7:00 AM
          </li>
          <li>
            <strong className='head2'>Treasure Hunt:</strong> 7:00 AM - 8:00 AM
          </li>
          <li>
            <strong className='head2'>Tambola Game:</strong> 8:00 AM - 9:00 AM
          </li>
          <li>
            <strong className='head2'>Free Breakfast:</strong> 9:00 AM
          </li>
        </ul>
      </div>

      <div className="schedule-section">
        <h2 className='head2'>Closing</h2>
        <ul>
          <li>
          After breakfast, packing and dispatch.
          </li>
          <li>
          Event Ends: 11:00 AM
          </li>
        </ul>
      </div>

    </div>
    </>
  );
};

export default Schedule;

import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation

const DJ = () => {
  return (
    <>
      <div className='up-two'></div>

      <div className="dj-container">
        <h2 className="title neonText head2">DJ Night</h2>
        <p className="dj-description">
          Dance the night away with top DJs spinning the latest tracks.
          The perfect atmosphere with LED lights, laser beams, and a lively dance floor.
        </p>

        <div className="dj-list">

        <div className="dj-card">
            <img src="./images/1.jpg" alt="DJ KARMA" className="dj-img" />
            <h3 className='head2'>DJ KARMA</h3>
            {/* <p>Unique musical experience and crowd favorite.</p> */}
            <a href="https://www.instagram.com/djkarmaindia?igsh=Zmg5Z240MW5jcHJj" className="book-button2">More</a>
          </div>
     
          <div className="dj-card">
            <img src="./images/7.jpg" alt="djasenofficial" className="dj-img" />
            <h3 className='head2'>DJ ASEN OFFICIAL </h3>
            {/* <p>High-energy mixes and captivating stage presence.</p> */}
            <a href="https://www.instagram.com/djasenofficial/?igsh=Mjk2bTRhOGo1djYz#" className="book-button2">More</a>
          </div>

         
          <div className="dj-card">
            <img src="./images/3.jpg" alt="DJ NAGMA" className="dj-img" />
            <h3 className='head2'>DJ NAGMA</h3>
            {/* <p>Blends classic hits with modern beats.</p> */}
            <a href="https://www.instagram.com/djane_nad?igsh=aHgwaHptaGJnOHc=" className="book-button2">More</a>
          </div>

          
          
        </div>
      </div>

      <div className="dj-container">
        

        <div className="dj-list">
          
          <div className="dj-card">
            <img src="./images/5.jpg" alt="DJ WAJEEHUDDIN" className="dj-img" />
            <h3 className='head2'>DJ WAJEEHUDDIN</h3>
            {/* <p>High-energy mixes and captivating stage presence.</p> */}
            <a href="https://www.instagram.com/djwa_g?igsh=ZXF0NTNpcjZmb2w2" className="book-button2">More</a>
          </div>

      
          {/* <div className="dj-card">
            <img src="./images/DJ6.jpg" alt="DJ SHREE" className="dj-img" />
            <h3 className='head2'>DJ SHREE</h3>
            <a href="https://www.instagram.com/dj.shree_?igsh=MTUya2FxN2pnaHl3OA%3D%3D&utm_source=qr" className="book-button2">More</a>
          </div> */}

          
          <div className="dj-card">
            <img src="./images/question-mark.jpg" alt="DJ Magic Hidden" className="dj-img" />
            <h3 className='head2'>SUPRISE ARTIST</h3>
            {/* <p>Unique musical experience and crowd favorite.</p> */}
            <a href="" className="book-button2">More</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DJ;



import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import necessary components
import Home from "./components/Home";
import DJ from "./components/Pages/DJ";
import Food from "./components/Pages/Food";
import Schedule from "./components/Pages/Schedule";
import Stay from "./components/Pages/Stay";
// import FireCampingParty from "./components/Pages/FireCampingParty";
// import GardenParty from "./components/Pages/GardenParty";
// import PoolParty from "./components/Pages/PoolParty";
import More from "./components/Pages/More";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/CommonPages/AboutUs";
import PrivacyPolicy from "./components/CommonPages/PrivacyPolicy";
import RefundPolicy from "./components/CommonPages/RefundPolicy";
import TermsAndConditions from "./components/CommonPages/TermsAndConditions";
import EntryPackages from "./components/Pages/EntryPackages";
import ScrollToTop from "./components/ScrollToTop";
import FireCamp from "./components/Pages/FireCamp";
import Riverside from "./components/Pages/Riverside";
import Games from "./components/Pages/Games";
// import FloatingButton from "./components/CommonPages/FloatingButton";

function App() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dj" element={<DJ />} />
        <Route path="/food" element={<Food />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/stay" element={<Stay />} />
        <Route path="/entrypackages" element={<EntryPackages />} />
        
        
        <Route path="/fire-camp" element={<FireCamp />} />
        <Route path="/river-side" element={<Riverside />} />
        <Route path="/games" element={<Games />} />
        {/* <Route path="/fire-camp" element={<FireCamp />} /> */}
        
        <Route path="/more" element={<More />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/tandc" element={<TermsAndConditions />} />
      </Routes>
      {/* <FloatingButton /> */}

      <Footer />
    </React.Fragment>
  );
}

export default App;

import React from "react";

const EventCancellationNotice = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Event Cancellation Notice</h1>
      <p style={styles.paragraph}>
        We regret to inform you that the event has been canceled due to unresolved issues with the resort owner. We sincerely apologize for any inconvenience caused.
      </p>
      <h2 style={styles.subheading}>Refund Details:</h2>
      <ul style={styles.list}>
        <li>Paytm Insider &amp; BookMyShow Bookings: Please contact their respective customer care teams for refunds.</li>
        <li>Bookings via Our Website: Refunds will be processed within 48 working hours.</li>
      </ul>
      <p style={styles.paragraph}>
        Thank you for your understanding and support.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "600px",
    margin: "auto",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
    color: "#333",
    backgroundColor:'#fff',
    borderRadius:'20px',
    marginTop:'50px',
    marginBottom:'50px',
  },
  heading: {
    fontSize: "24px",
    color: "#c62828",
    textAlign: "center",
  },
  subheading: {
    fontSize: "20px",
    color: "#000",
    marginTop: "20px",
  },
  paragraph: {
    fontSize: "16px",
    margin: "10px 0",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
    margin: "10px 0",
  },
};

export default EventCancellationNotice;

import React from 'react';
import { Link } from 'react-router-dom';

const More = () => {
  return (
    <>
      <div className='up-two'></div>

      <div className="about-event-container">
        <h1 className="title neonText head2">About the Event</h1>
        <p className="event-description">
          Event Flow Asia presents Corporate Nightlife Cocktail Party—an electrifying event that combines fun, sophistication, and entertainment, taking place at the scenic Harivillu Resorts on December 21st, 2024. This immersive gathering is a perfect blend of live games, cocktail indulgence, vibrant music, and chill-out zones for corporate guests and their loved ones. With a carefully curated event lineup, this party promises a night of unforgettable experiences that cater to all tastes.
        </p>

        <div className="event-details">
          <h2 className='head2'>Event Overview</h2>
          <p>
            The Corporate Nightlife Cocktail Party is designed to provide a refreshing break from the ordinary, offering guests a day filled with interactive games and a night bursting with music, food, and celebration. Whether you're a group of friends, a couple, or attending as a solo participant, the event has something exciting to offer at every step.
          </p>

          <Link to='/schedule' className='book-button2'><h2 style={{color:'#fff'}}>Event Schedule</h2></Link>
          {/* <ul className="event-schedule">
            <button>Event Schedule</button>
          </ul> */}

          <hr className="divider" />

          <h2 className='head2'>Detailed Breakdown</h2>
          {/* <h3 className='head2'>Live Games (3:00 PM - 8:00 PM)</h3> */}
          <ul className="live-games">
            <li>Beer Pong – Test your aim and try to land the ball in the opponent’s cup.</li>
            <li>Who Drinks Beer First? – A fast-paced race to finish your beer.</li>
            <li>Corn Hole India Game Challenge – A unique Indian twist to the classic Corn Hole game.</li>
            <li>Dapo Long Spin Challenge – Compete to see who can spin the Dapo the longest.</li>
            {/* <li>Live PUBG Gaming Challenge – Step into the virtual world and prove your mettle as the ultimate PUBG champion, whether solo or in teams.</li> */}
          </ul>

          <h3 className='head2'>Cocktail & Kebabs (8:00 PM - 12:00 AM)</h3>
          <p>
            The night comes alive with a cocktail party like no other, featuring unlimited cocktails, kebabs, and the backdrop of a live DJ. Guests will enjoy Bollywood and techno beats, creating a perfect balance of rhythm and relaxation.
          </p>
          <ul className="cocktail-highlights">
            <li>Virgin Sports: A delightful mix of vodka, white rum, and orange slices.</li>
            <li>CocoGin: A tropical twist featuring gin and coconut water.</li>
            <li>Fire Shots: Flaming shots to turn up the heat.</li>
            <li>Whisky Sour and Whisky Highball: Classic whisky drinks with a contemporary flair.</li>
            <li>Brandy with Warm Water: A cozy, warming choice.</li>
          </ul>

          <h3 className='head2'>Kebab Menu</h3>
          <p>
            Tandoori Chicken, Pineapple, Aloo Kebabs, Tandoori Prawns, and Fish Tikka – a mouth-watering spread to pair with your cocktails.
          </p>

          <h3 className='head2'>Dinner (Served at 9:00 PM)</h3>
          <p>
            For all participants, a delectable dinner awaits, with a mix of Indian classics: Bagara Rice, Chicken Curry, Paneer Curry, Sambar, White Rice, Curd, and Pickle. To round off the meal, indulge in some sweet Ice Cream.
          </p>

          <h3 className='head2'>Post-Midnight Chill-Out Zones</h3>
          <ul className="chill-out-zones">
            <li>Stags at the Camping Side: Perfect for singles to enjoy an energetic camp-side experience with music and games.</li>
            <li>Groups at the Riverside: Groups can party by the river, soaking in the ambiance with space to dance and relax.</li>
            <li>Couples at the Garden Side: A romantic garden-side setting with soft lighting, live music, and an intimate atmosphere designed for couples.</li>
          </ul>

          <h3 className='head2'>Morning Breakfast (Served at 8:00 AM)</h3>
          <p>
            The next day starts with a fresh and fulfilling breakfast to rejuvenate after a night of fun: Options include Poori with Aloo Curry, Upma with Chutney, Tea & Coffee, Fresh Fruit Juice, Bread & Jam, and Milk & Cornflakes.
          </p>

          <hr className="divider" />

          <h2 className='head2'>Entry Packages & Early Bird Discounts</h2>
          <ul className="entry-packages">
            <li>Basic Entry for Stags: ₹2,500 (without liquor & kebabs)</li>
            <li>Stag Entry with Unlimited Cocktails & Kebabs: ₹4,999</li>
            <li>Couple Entry: ₹6,999</li>
            <li>Group Packages: Starting from ₹19,999 for 5 people</li>
            <li>Early Bird Discounts for the first 200 entries offer packages as low as ₹3,999 for stags and ₹5,999 for couples.</li>
          </ul>

          <h3 className='head2'>Accommodation Options</h3>
          <p>
            For guests wanting to stay overnight, Harivillu Resorts offers luxurious camping tents and exclusive suites. Each space is tailored for couples, groups, or families, ensuring every participant enjoys a unique and comfortable experience.
          </p>

          <h2 style={{ textAlign: 'center' }} className='head2'>Book Your Spot!</h2>
          <p style={{ textAlign: 'center' }}>
            Don’t miss out on the fun! Secure your entry now at discounted prices by booking through our website or WhatsApp.
          </p>
          <p style={{ textAlign: 'center' }}>
            For more details and bookings:<br />
            Visit: <a href="https://www.eventflow.asia" target="_blank" rel="noopener noreferrer" className='head2'>www.eventflow.asia</a><br />
            WhatsApp us: <a href="https://wa.me/9553321111" target="_blank" rel="noopener noreferrer" className='head2'>+91 92 93 22 5555</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default More;
